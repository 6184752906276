import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DashboardContainer from './Components/Dashboard/DashboardContainer';
export default (
  <Switch>
    <Route exact path="/" component={DashboardContainer} />
    <Route path='/dashboard' component={DashboardContainer} />
  </Switch>
);

