import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import routes from './routes';
import configureStore from './store';
import './App.css';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";

export const msalInstance = new PublicClientApplication(msalConfig);


const syncHistoryWithStore = (store, history) => {
  const { router } = store.getState();
  if (router && router.location) {
    history.replace(router.location);
  }
};

const initialState = {};
const routerHistory = createMemoryHistory();
const store = configureStore(initialState, routerHistory);
syncHistoryWithStore(store, routerHistory);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isAuthenticated: false
    }
  }


  componentDidMount() {
    console.log(process.env.NODE_ENV)
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      this.setState({ isAuthenticated: true })
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === "LOGIN_SUCCESS" && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

      }
    }, error => {
      console.error('error', error);
    });


    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in 
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page 
        msalInstance.loginRedirect();
      }
    }).catch(err => {
      console.log(err);
    });
  }
  render() {
    return (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <div className="App">
            {this.state.isAuthenticated === true ? <Router history={routerHistory}>{routes}</Router> : <span></span>}
          </div>
        </Provider>
      </MsalProvider>
    );
  }

}
export default App;

