import React, { useState } from 'react';
import DashboardHeader from './DashComp/DashboardHeader.js';
import DashboardBody from './DashComp/DashboardBody';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import * as util from '../../Utils/Utility';
import CircularIndeterminate from '../commonComponents/Loader';
import Alerts from '../commonComponents/Alerts';
import CommonModal from '../Modals/CommonModal.js';
export default function Dashboard(props) {
    const [msalTrigger, setMsalTrigger] = useState(false);

    const { instance, accounts } = useMsal();
    if (props.tokenExpired === true) {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" })
    }
    if (accounts.length > 0 && !props.token && !msalTrigger) {
        setMsalTrigger(true)
        console.log(accounts, 'current account')
        const request = {
            scopes: ["User.Read"],
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
            props.actions.storeAccessToken(response.accessToken);
            sessionStorage.setItem("accessToken", response.accessToken)
        }).catch(error => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(request).then(response => {
                    props.actions.storeAccessToken(response.accessToken);
                });
            }
        });
    }

    function logoutRedirect() {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" })
    }
    React.useEffect(() => {
        props.actions.resetStore();
        props.actions.setDetailView(false)
        props.actions.setValidProject(true)
        return () => {
            props.actions.resetStore();
            console.log("cleaned up");
        };
    }, [props.actions])
    React.useEffect(() => {
        props.token !== null && props.token !== undefined && props.actions.validateUsers(props.token)
    }, [props.token])

    return (
        <>
            {accounts.length > 0 ? <div>
                {props.setLoader && <CircularIndeterminate />}
                <DashboardHeader notificationCounter={props.notificationCounter} CounterloginActions={props.loginActions} actions={props.actions} notification={props.notification} />
                <DashboardBody
                    searchGrid={props.gridData && props.gridData.searchRowData}
                    actions={props.actions}
                    accordionData={props.accordion}
                    isDetail={props.isDetailPage}
                    gridData={props.gridData}
                    account={accounts}
                    formData={props.addFormData}
                    validProject={props.validProject}
                    mode={props.mode}
                    environment={props.environment} />
                {/* {props.notification && <Alerts />} */}
                {props.alert && Object.keys(props.alert).length > 0 && <Alerts data={props.alert} action={props.actions} />}
            </div> : null}
            {props.whiteListed === false && <CommonModal mBody={`Your email is not whitelisted in LMS`} title={"Unautherized user"} footer={"OK"} onClick={logoutRedirect} />}
        </>
    )

}
